import { useQuery } from "@tanstack/react-query";
import { getPackages } from "../../services/subscriptions";

const useGetPackages = (fetchPackages: boolean) => {
    return useQuery({
        queryKey: ['packages'],
        queryFn: getPackages,
        enabled: fetchPackages,
        refetchOnWindowFocus: false,
    });
};

export default useGetPackages;