import api from ".";

import cookiesHelper from "../helpers/cookies";

import { TApplicantKey } from "../types/index.types";

export const getPassportRecords = async (applicantKey: TApplicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.get(`passports/${applicantKey}`);
        return res.data?.data?.length ? res.data.data : [];
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const saveTravelDocs = async (applicantKey: TApplicantKey, payload: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`passports/${applicantKey}`, payload);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const deletePassportRecords = async (payload: any, applicantKey: TApplicantKey) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`passports/delete/${applicantKey}`, payload);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};