class CookiesHelper {
    getCookie(name: string): string | null {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const cookieArray = decodedCookie.split(';');

        for (const element of cookieArray) {
            let cookie = element;
            while (cookie.startsWith(' ')) {
                cookie = cookie.substring(1);
            };
            if (cookie.startsWith(cookieName)) {
                return cookie.substring(cookieName.length, cookie.length);
            };
        };
        return null;
    };

    setCookie(name: string, value: string, exp: number): void {
        const d = new Date();
        d.setTime(d.getTime() + exp);

        const expires = "expires=" + d.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    removeCookie(name: string): void {
        if (this.getCookie(name)) {
            const d = new Date();
            d.setTime(d.getTime() - (24 * 60 * 60 * 1000));

            const expires = "expires=" + d.toUTCString();
            document.cookie = name + "=;" + expires + ";path=/";
        };
    };
};

const cookiesHelper = new CookiesHelper();

export default cookiesHelper;