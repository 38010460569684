import { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";

interface IUseIdleTimeoutProps {
    onIdle: () => void;
    onActionIp: () => void;
    idleTime: number;
}

const useIdleTimeout = ({ onIdle, onActionIp, idleTime = 1 }: IUseIdleTimeoutProps) => {
    const idleTimeout = 1000 * idleTime;
    const [isIdle, setIdle] = useState(false);

    const handleIdle = () => {
        setIdle(true)
        // onIdle();
    }

    const handleAction = () => {
        setIdle(false);
        onActionIp();
    }

    const idleTimer = useIdleTimer({
        timeout: idleTimeout,
        promptBeforeIdle: idleTimeout / 2,
        onPrompt: onIdle,
        onIdle: handleIdle,
        debounce: 500,
        onAction: handleAction
    });

    // const { getRemainingTime, getLastActiveTime } = idleTimer;

    useEffect(() => {
        const interval = setInterval(() => {
            // console.log(getRemainingTime());
            console.log('user inactive');
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, []);

    return {
        isIdle,
        setIdle,
        idleTimer
    }
}
export default useIdleTimeout;