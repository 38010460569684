import { useMutation } from "@tanstack/react-query";

import { inviteOrganization } from "../../services/invitations";

const useInviteOrganization = () => {
    const mutationFn = async (data: any) => {
        const { payload } = data;

        return await inviteOrganization(payload);
    };

    return useMutation({
        mutationFn,
        onMutate: (variables: any) => { },
        onSuccess: (response: any, variables: any) => {
            if (response?.data) {
                if (variables?.successCallback) variables.successCallback(response.data);
            }
        },
        onError: (error: any, variables: any) => {
            if (variables?.errorCallback) variables.errorCallback(error.response.data);
        },
        onSettled: (data: any, error: any, variables: any) => { }
    });
};

export default useInviteOrganization;