import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

import { signOut } from "../../services/auth";

import cookiesHelper from "../../helpers/cookies";
import { getTrackingPath } from "../../helpers/tracking";

import { resetSession } from "../../store/slices/global";

const useSignOut = () => {
    const dispatch = useDispatch();

    const mutationFn = async () => {
        return await signOut();
    };

    return useMutation({
        mutationFn,
        onMutate: (variables: any) => { },
        onSuccess: (response: any, variables: any) => { },
        onError: (error: any, variables: any) => { },
        onSettled: (data: any, error: any, variables: any) => {
            if (
                window.location.pathname !== '/auth' &&
                !window.location.pathname.includes('/reset-password') &&
                !window.location.pathname.includes('/password-changed') &&
                !window.location.pathname.includes('/activate')
            ) {
                cookiesHelper.removeCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
                cookiesHelper.removeCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__IMMI_QUEST__MAIN as string);
                cookiesHelper.removeCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__IMMI_QUEST__SPOUSE as string);
                cookiesHelper.removeCookie(process.env.REACT_APP_INSTANCE_TOKEN_NAME__VC_QUEST__MAIN as string);
                dispatch(resetSession());
                window.location.href = getTrackingPath('/auth');
            };
        }
    });
};

export default useSignOut;