import { useMutation } from "@tanstack/react-query";

import { generateAuthProviderCode } from "../../services/auth_providers";

const useGenerateAuthProviderCode = () => {
    const mutationFn = async (payload: any) => {
        return await generateAuthProviderCode(payload.data);
    }

    return useMutation({
        mutationFn,
        onMutate: (variables: any) => {
            variables.setIsProcessing(true);
            variables.setApiError(null);
        },
        onSuccess: (data: any, variables: any) => {
            variables.successCallback(data);
            variables.setIsProcessing(false);
        },
        onError: (error: any, variables: any) => {
            variables.setApiError(error.response.data.message);
            variables.setIsProcessing(false);
        },
        onSettled: (data: any, error: any, variables: any) => {
            variables.setIsProcessing(false);
        },
    });
};
export default useGenerateAuthProviderCode;