class ClassesHelper {
    add(classList: DOMTokenList, className: string): void {
        if (!classList.contains(className)) {
            classList.add(className);
        };
    };

    remove(classList: DOMTokenList, className: string): void {
        if (classList.contains(className)) {
            classList.remove(className);
        };
    };

    toggle(classList: DOMTokenList, className: string): void {
        classList.toggle(className);
    };

    contains(classList: DOMTokenList, className: string): boolean {
        return classList.contains(className);
    };
};

const classesHelper = new ClassesHelper();
export default classesHelper;