import { Navigate, Outlet, useLocation } from "react-router-dom";

import authHelper from "../helpers/auth";
import { getTrackingPath } from "../helpers/tracking";

const PrivateRoutes = () => {
    const location = useLocation();

    const tokenValidationRes = authHelper.isAuthTokenValid();

    return (
        tokenValidationRes.result
            ? <Outlet />
            : <Navigate to={getTrackingPath('/auth')} state={{ from: location }} replace />
    );
};

export default PrivateRoutes;