import api from ".";

import cookiesHelper from "../helpers/cookies";

export const getConnections = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.get(`invitations/connections`);
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

export const respondToOrganizationInvitation = async (payload: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const res = await api.post(`invitations/respond`, payload);
        return res.data;
    } catch (error: any) {
        throw error?.response?.data;
    };
};

export const getNewInvitationsCount = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.get(`invitations/new/count`);
        return response.data;
    } catch (error: any) {
        throw error;
    };
};

export const inviteOrganization = async (payload: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.post(`invitations/invite/organization`, payload);
    } catch (error: any) {
        throw error;
    }
};