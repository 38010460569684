import api from ".";

import cookiesHelper from "../helpers/cookies";

export const reportBug = async (data: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.post(`users/report-bug`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
    } catch (error: any) {
        throw error;
    }
};
