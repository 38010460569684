import { useMutation } from "@tanstack/react-query";

import { resendSpouseInvitation } from "../../services/applicants";

const useResendSpouseInvitation = () => {
    const mutationFn = async (data: any) => {
        const { payload } = data;

        return await resendSpouseInvitation(payload);
    };

    return useMutation({
        mutationFn,
        onMutate: (variables: any) => { },
        onSuccess: (response: any, variables: any) => {
            if (response) {
                variables.setServerSuccess('Invitation has been sent successfully.');
            } else {
                variables.setServerError('Error occurred while sending invitation.');
            };
        },
        onError: (error: any, variables: any) => {
            variables.setServerError(error?.message ?? 'Error occurred while sending invitation.');
        },
        onSettled: (data: any, error: any, variables: any) => {
            variables.setIsProcessRunning(null);
        },
    });
};

export default useResendSpouseInvitation;