import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { updateStatusInfoAtOrganizations } from "../../services/applicants";

import { closeModal, openModal } from "../../store/slices/modal";
import { getTrackingPath } from "../../helpers/tracking";

const useUpdateStatusInfoAtOrganizations = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const mutationFn = async (data: any) => {
        return await updateStatusInfoAtOrganizations(data.applicantKey);
    };

    return useMutation({
        mutationFn,
        onMutate: (variables: any) => { },
        onSuccess: (response: any, variables: any) => {
            if (response) {
                if (variables.purpose === 'invitation_response') {
                    dispatch(openModal({
                        name: 'MESSAGE_MODAL',
                        data: {
                            closeOnEsc: true,
                            closeOnOutsideClick: true,
                            icon: 'success',
                            title: 'Status information updated!',
                            titleColor: 'primary',
                            content: 'Your status information has been updated successfully organizations. You can now view your status information in status manager.',
                            buttons: [
                                {
                                    label: 'Status Manager',
                                    style: 'primary',
                                    onClick: () => {
                                        dispatch(closeModal('MESSAGE_MODAL'))
                                        setTimeout(() => {
                                            navigate(getTrackingPath('/status-manager'))
                                        }, 500);
                                    }
                                },
                                { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                            ]
                        },
                    }));
                };
            };
        },
        onError: (error: any, variables: any) => {
            if (variables.purpose === 'invitation_response') {
                dispatch(openModal({
                    name: 'MESSAGE_MODAL',
                    data: {
                        closeOnEsc: true,
                        closeOnOutsideClick: true,
                        icon: 'error',
                        title: 'Status Update Failed',
                        titleColor: 'error',
                        content: 'An error occurred while updating your status information at organizations. Please try again later.',
                        buttons: [
                            { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                        ]
                    },
                }));
            };
        },
        onSettled: (data: any, error: any, variables: any) => { }
    });
};

export default useUpdateStatusInfoAtOrganizations;