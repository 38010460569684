import { createSlice } from "@reduxjs/toolkit";
import { produce } from 'immer';

import { GlobalSliceState, StoreState, ISessionData, IApplicantData } from "../../types/store.types";
import { TApplicantKey } from "../../types/index.types";

const initialState: GlobalSliceState = {
    sessionData: null,
    subscription: null,
    subscribedUser: null,
    paymentRedirectUrl: null,
};

const globalSlice = createSlice({
    name: "global",
    initialState,
    reducers: {
        resetSession: (state) => {
            return produce(state, draft => {
                draft.subscription = null;
                draft.subscribedUser = null;
                draft.sessionData = null;
                draft.paymentRedirectUrl = null;
            });
        },
        setSessionData: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = action.payload;
            });
        },
        setCurrentApplicant: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = {
                    ...state.sessionData,
                    currentApplicant: action.payload
                } as ISessionData;
            });
        },
        setApplicantData: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = {
                    ...state.sessionData,
                    [action.payload.applicantKey]: action.payload.applicantData
                } as ISessionData;
            });
        },
        setResultDate: (state, action) => {
            return produce(state, draft => {
                draft.sessionData = {
                    ...state.sessionData,
                    [action.payload.applicantKey as TApplicantKey]: {
                        ...state.sessionData?.[action.payload.applicantKey as TApplicantKey],
                        result_date: action.payload.result_date
                    }
                } as ISessionData;
            });
        },
        setQuestionnaireData: (state, action) => {
            return produce(state, draft => {
                const applicantData = state.sessionData?.[action.payload.applicantKey as TApplicantKey];
                const questionnaireData = applicantData?.[action.payload.questionnaire as keyof IApplicantData] || {};

                draft.sessionData = {
                    ...state.sessionData,
                    [action.payload.applicantKey]: {
                        ...applicantData,
                        [action.payload.questionnaire]: {
                            ...questionnaireData,
                            date_modified: action.payload.date_modified,
                            last_section_id: action.payload.last_section_id,
                            last_page_id: action.payload.last_page_id,
                            status: action.payload.status
                        }
                    }
                } as ISessionData;
            });
        },
        setSubscription: (state, action) => {
            return produce(state, draft => {
                draft.subscription = action.payload;
            });
        },
        setSubscribedUser: (state, action) => {
            return produce(state, draft => {
                draft.subscribedUser = action.payload;
            });
        },
        setPaymentRedirectUrl: (state, action) => {
            return produce(state, draft => {
                draft.paymentRedirectUrl = action.payload;
            });
        },
    }
});

export const {
    resetSession,
    setSessionData,
    setCurrentApplicant,
    setApplicantData,
    setResultDate,
    setQuestionnaireData,
    setSubscription,
    setSubscribedUser,
    setPaymentRedirectUrl,
} = globalSlice.actions;
export default globalSlice.reducer;

// Selectors
export const getSessionData = () => (state: StoreState) => state.global.sessionData;
export const getUserSubscription = () => (state: StoreState) => state.global.subscription;
export const getSubscribedUser = () => (state: StoreState) => state.global.subscribedUser;
export const getIsSpouseSelected = () => (state: StoreState) => state.global.sessionData?.currentApplicant && state.global.sessionData?.spouse?.id && (state.global.sessionData.currentApplicant === state.global.sessionData.spouse.id);
export const getCurrentApplicantKey = () => (state: StoreState) => (state.global.sessionData?.currentApplicant && state.global.sessionData?.spouse?.id && (state.global.sessionData.currentApplicant === state.global.sessionData.spouse.id)) ? 'spouse' : 'main';
export const getCurrentApplicantData = () => (state: StoreState) => state.global.sessionData?.[(state.global.sessionData?.currentApplicant && state.global.sessionData?.spouse?.id && (state.global.sessionData.currentApplicant === state.global.sessionData.spouse.id)) ? 'spouse' : 'main'] ?? null;
export const getPaymentRedirectUrl = () => (state: StoreState) => state.global.paymentRedirectUrl;