import { useMutation } from "@tanstack/react-query";

import { registerAuthProvider } from "../../services/auth_providers";

const useRegisterAuthProvider = () => {
    const mutationFn = async (payload: any) => {
        return await registerAuthProvider(payload.data);
    }
    return useMutation({
        mutationFn,
        onMutate: (variables: any) => {
            variables.setIsProcessing(true);
            variables.setApiError(null);
        },
        onSuccess: (response: any, variables: any) => {
            variables.successCallback(response.data);
            variables.setIsProcessing(false);
        },
        onError: (error: any, variables: any) => {
            variables.setApiError(error.response.data.message);
            variables.setIsProcessing(false);
        },
        onSettled: (data: any, error: any, variables: any) => {
            variables.setIsProcessing(false);
        },
        retry: 0,
    });
};
export default useRegisterAuthProvider;