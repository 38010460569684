import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import { saveTravelDocs } from "../../services/passports";

import { closeModal, openModal } from "../../store/slices/modal";
import { getCurrentApplicantKey, getSessionData } from "../../store/slices/global";

import useUpdateStatusInfoAtOrganizations from "../applicants/useUpdateStatusInfoAtOrganizations";
import useUpdateStatusManagerInfoInCRM from "../applicants/useUpdateStatusManagerInfoInCRM";

import { triggerEvent } from "../../helpers/tracking";

const useSaveTravelDocs = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const currentApplicantKey = useSelector(getCurrentApplicantKey());
    const sessionData = useSelector(getSessionData());

    const { mutate: updateStatusInfoAtOrganizations } = useUpdateStatusInfoAtOrganizations();
    const { mutate: updateStatusManagerInfoInCRM } = useUpdateStatusManagerInfoInCRM();

    const mutationFn = async (data: any) => {
        return await saveTravelDocs(currentApplicantKey, data.payload);
    };

    return useMutation({
        mutationFn,
        onMutate: (variables: any) => { },
        onSuccess: (response: any, variables: any) => {
            if (response) {
                queryClient.invalidateQueries({ queryKey: ['passport_records'] });
                queryClient.invalidateQueries({ queryKey: ['status_info'] });

                updateStatusInfoAtOrganizations({
                    applicantKey: currentApplicantKey,
                    purpose: 'info_update'
                });

                triggerEvent({
                    name: 'status_manager_travel_doc_attached',
                    action: 'api_response',
                    category: 'Status Manager',
                    email: sessionData?.email,
                    user_id: sessionData?.user_id,
                    profile_type: currentApplicantKey,
                });

                updateStatusManagerInfoInCRM();
            };
        },
        onError: (error, variables) => {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    closeOnEsc: true,
                    closeOnOutsideClick: true,
                    icon: 'error',
                    title: 'Error occured!',
                    titleColor: 'primary',
                    content: error.message ?? 'An error occured while processing your request. Please try again later.',
                    buttons: [
                        { label: 'Close', style: 'info', onClick: () => dispatch(closeModal('MESSAGE_MODAL')) },
                    ]
                },
            }));
        },
        onSettled: (data, error, variables) => {
            variables.setIsProcessing(false);
        }
    });
};

export default useSaveTravelDocs;