import React, { useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { BsX } from "react-icons/bs";

import { isModalActive, closeModal, getModalData } from "../../store/slices/modal";

import useRespondToOrganizationInvitation from "../../hooks/invitations/useRespondToOrganizationInvitation";

import { getDateString } from "../../helpers/utility";
import { useNavigate } from "react-router-dom";
import { getTrackingPath } from "../../helpers/tracking";

const modalName = "ORGANIZATION_INVITATIONS_LIST_MODAL";
const modalTitle = "Organization Invitations";
const modalClass = "organization_invitation";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const OrganizationInvitationsListModal = () => {

    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));
    const modalData = useSelector(getModalData(modalName));
    const navigate = useNavigate();

    const [processingFor, setIsProcessingFor] = useState({ response: null, idx: null });
    const [apiError, setApiError] = useState({ message: null, idx: null });

    const { mutate: respondToOrganizationInvitation } = useRespondToOrganizationInvitation();

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
        navigate(getTrackingPath('/'), { replace: true, state: { recent_login: false } });
    };

    const getAddress = (employer: any) => {
        if (!employer) return '';

        const job = employer.jobs[0];
        if (!job) return '';

        let address = '';
        if (job.street) address += job.street;
        if (job.city) address += `, ${job.city}`;
        if (job.province) address += `, ${job.province}`;
        if (job.postal) address += `, ${job.postal}`;

        return address;
    };

    const onRespondToInvitation = (idx: any, invitationId: any, response: any) => {
        if (processingFor.response || processingFor.idx) return;

        setApiError({ message: null, idx: null });
        setIsProcessingFor({ response, idx });

        respondToOrganizationInvitation({
            idx,
            payload: {
                id: invitationId,
                status: response
            },
            setIsProcessingFor,
            setApiError,
        })

        dispatch(closeModal(modalName));
        navigate(getTrackingPath('/'), { replace: true, state: { recent_login: false } });
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className="imm__organization_invitation__inner_wrap">
                    <button className="close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>

                    <h4 className="color_primary">Employer Invitations</h4>

                    <p className="imm__organization_invitation__note mice pt-2">
                        Once you accept the invitation, the employer will have view-only access to your immigration status information and they won't be able to change or edit it. Their access will be limited to viewing only information related to passports, permits and visas.
                    </p>

                    <div className="imm__organization_invitations__list">

                        {
                            modalData?.filter((data: any) => data.status === "pending").map((data: any, idx: number) =>
                                !data?.payload?.employers?.length
                                    ? <h5 className="color_error">No employers.</h5>
                                    : <div className="imm__organization_invitation__list">
                                        <p className="color_primary wt-600 pt-1">
                                            {data?.payload?.organization_name}&nbsp;
                                            <span className="color_disabled wt-500 pt-2">({getDateString(data.date_created, 'MMM DD-YYYY')})</span>
                                        </p>
                                        <p className="imm__organization_invitation__note mice pt-1 mb-1">
                                            Following are the employers associated with the organization.
                                            All the employers will have read only access to your information.
                                        </p>
                                        {
                                            data.payload.employers.map((employer: any, idx: number) =>
                                                <div className="imm__organization_invitation__item" key={idx}>
                                                    <p className="color_primary wt-600">{employer.legal_name}</p>
                                                    <p className="imm__organization_invitation__item_address">{getAddress(employer)}</p>
                                                </div>
                                            )
                                        }

                                        <div className="imm__organization_invitation__action">
                                            <button
                                                className={`imm__regular__btn imm__btn__tertiary btn_fit_content`}
                                                onClick={() => onRespondToInvitation(1, data.id, 'refused')}
                                                disabled={processingFor.idx === 1}
                                            >
                                                {
                                                    processingFor.response === 'refused'
                                                        ? <i className='imm__btn_loader' />
                                                        : 'Refuse Invitation'
                                                }
                                            </button>
                                            <button
                                                className={`imm__regular__btn imm__btn__primary btn_fit_content`}
                                                onClick={() => onRespondToInvitation(1, data.id, 'accepted')}
                                                disabled={processingFor.idx === 1}
                                            >
                                                {
                                                    processingFor.response === 'accepted'
                                                        ? <i className='imm__btn_loader' />
                                                        : 'Accept Invitation'
                                                }
                                            </button>
                                        </div>
                                        {apiError.idx === 1 && <p className="text_center color_error mice wt-600 pt-3">{apiError.message}</p>}
                                    </div >
                            )
                        }
                    </div>
                </div>
            </div>
        </ReactModal >
    );
};

export default OrganizationInvitationsListModal;