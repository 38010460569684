import { useMutation } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { updateStatusManagerInfoInCRM } from "../../services/applicants";

import { getCurrentApplicantKey } from "../../store/slices/global";

const useUpdateStatusManagerInfoInCRM = () => {

    const currentApplicantKey = useSelector(getCurrentApplicantKey());

    const mutationFn = async () => {
        if (currentApplicantKey !== 'main') {
            return;
        } else {
            return await updateStatusManagerInfoInCRM(currentApplicantKey);
        };
    };

    return useMutation({
        mutationFn,
        onMutate: () => { },
        onSuccess: () => { },
        onError: () => { },
        onSettled: () => { }
    });
};

export default useUpdateStatusManagerInfoInCRM;