import React, { useState } from "react";

import useInviteOrganization from "../../../hooks/invitations/useInviteOrganization";

const AddOrganization = (props: any) => {
    const { modalData, connectionType, setConnectionType, onCloseModal } = props;

    const { mutate: inviteOrganization } = useInviteOrganization();

    const [serverError, setServerError] = useState('');
    const [isProcessRunning, setIsProcessRunning] = useState(false);

    const [formData, setFormData] = useState({
        company_name: "",
        contact_name: "",
        email: "",
    });
    const [errors, setErrors] = useState({});

    const validate = () => {
        const errors = {};

        if (!formData.company_name?.trim()?.length) {
            (errors as any).company_name = 'Company name is required';
        }

        if (!formData.contact_name?.trim()?.length) {
            (errors as any).contact_name = 'Contact name is required';
        }

        if (!formData.email?.trim()?.length) {
            (errors as any).email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            (errors as any).email = 'Email address is invalid';
        }

        return errors;
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const onAddInvitation = () => {
        setServerError('')
        const validationErrors = validate();
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            setIsProcessRunning(true);

            inviteOrganization({
                payload: {
                    company_name: formData.company_name,
                    contact_name: formData.contact_name,
                    email: formData.email,
                },
                successCallback: () => {
                    onCloseModal()
                    setIsProcessRunning(false)
                },
                errorCallback: (error: any) => {
                    setIsProcessRunning(false);
                    if (error) {
                        setServerError(error?.message)
                    }
                },
            });
        }
    }


    return (
        <div className="imm__manage_connection_inner">

            <h3 className="dark_color">{"Invite an Employer"}</h3>

            <br />
            <div className="imm__manage_connection_inner_box">

                {!modalData?.singleView &&
                    <div className="imm__manage_spouse__model_view_input_group">
                        <label>Type</label>
                        <select value={connectionType} onChange={(obj) => setConnectionType(obj.target.value)}>
                            <option value="spouse">Spouse</option>
                            <option value="organization">Employer</option>
                        </select>
                    </div>
                }

                <div className="imm__manage_spouse__model_view_input_group">
                    <label>Company Name</label>
                    <input
                        name="company_name"
                        placeholder="Enter company name"
                        type="text"
                        value={formData.company_name}
                        onChange={handleChange}
                    />
                    {(errors as any).company_name && <h6 className='color_error'>{(errors as any).company_name}</h6>}
                </div>

                <div className="imm__manage_spouse__model_view_input_group">
                    <label>Contact Name</label>
                    <input
                        name="contact_name"
                        placeholder="Enter contact name"
                        type="text"
                        value={formData.contact_name}
                        onChange={handleChange}
                    />
                    {(errors as any).contact_name && <h6 className='color_error'>{(errors as any).contact_name}</h6>}
                </div>

                <div className="imm__manage_spouse__model_view_input_group">
                    <label>Contact email</label>
                    <input
                        name="email"
                        placeholder="Enter email"
                        type="text"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    {(errors as any).email && <h6 className='color_error'>{(errors as any).email}</h6>}
                </div>


                {serverError && <h6 className='color_error mt-3'>{serverError}</h6>}

                <div className="imm__manage_spouse__actions_box">
                    <button disabled={isProcessRunning} className={`btn_block imm__regular__btn imm__btn__tertiary mt-3`} onClick={() => onCloseModal()}>
                        Discard
                    </button>
                    <button disabled={isProcessRunning} className={`btn_block imm__regular__btn imm__btn__primary mt-3`} onClick={() => onAddInvitation()}>
                        {
                            isProcessRunning
                                ? <i className='imm__btn_loader' />
                                : `Save`
                        }
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddOrganization;