import React, { useRef, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { BsX } from "react-icons/bs";

import { isModalActive, closeModal } from "../../store/slices/modal";

import useReportBug from "../../hooks/reports/useReportBug";

import classesHelper from '../../helpers/classes';

const modalName = "REPORT_BUG_MODAL";
const modalTitle = "Report Bug";
const modalClass = "report_bug";

const topupLvl = 0;

ReactModal.setAppElement('#root');
const ReportBugModal = () => {

    const bugLocationRef = useRef(null);
    const bugDescrRef = useRef(null);
    const fileInputRef = useRef(null);

    const dispatch = useDispatch();
    const isModalVisible = useSelector(isModalActive(modalName));

    const [bugLocation, setBugLocation] = useState('');
    const [bugDescription, setBugDescription] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [isProcessRunning, setIsProcessRunning] = useState(false);
    const [operationStatus, setOperationStatus] = useState<string | null>(null);

    const { mutate: reportBug } = useReportBug();

    const handleResetFileInput = () => {
        if (fileInputRef.current) {
            (fileInputRef.current as any).value = "";
            (fileInputRef.current as any).type = "text";
            (fileInputRef.current as any).type = "file";
        }
    };

    const resetModalStates = () => {
        setBugLocation('');
        setBugDescription('');
        setImage(null);
        handleResetFileInput();
        setIsProcessRunning(false);
        setOperationStatus(null);
    };

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        resetModalStates();
        dispatch(closeModal(modalName));
    };

    const onSubmit = () => {
        const bugLocationEle = bugLocationRef?.current;
        const bugDescrEle = bugDescrRef?.current;
        const errorClass = 'imm__input_container__primary__error';

        if (bugLocationEle) classesHelper.remove((bugLocationEle as any).classList, errorClass);
        if (bugDescrEle) classesHelper.remove((bugDescrEle as any).classList, errorClass);
        setOperationStatus(null);

        if (!bugLocation?.trim()?.length) {
            if (bugLocationEle) classesHelper.add((bugLocationEle as any).classList, errorClass);
        } else if (!bugDescription?.trim()?.length) {
            if (bugDescrEle) classesHelper.add((bugDescrEle as any).classList, errorClass);
        } else {
            setIsProcessRunning(true);

            const formData = new FormData();
            formData.append('bugLocation', bugLocation);
            formData.append('bugDescription', bugDescription);
            if (image) formData.append('file', image);

            reportBug({
                payload: formData,
                successCallback: () => {
                    setOperationStatus('success');
                    setBugLocation('');
                    setBugDescription('');
                    setImage(null);
                    handleResetFileInput();
                },
                errorCallback: () => {
                    setOperationStatus('error');
                },
                callback: () => {
                    setIsProcessRunning(false);
                },
            });
        }
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`imm__modal_overlay ${topupLvl ? `imm__modal_overlay__topup${topupLvl}` : ''} imm__${modalClass}__modal_overlay`}
            className={`imm__modal_dialog ${topupLvl ? `imm__modal_dialog__topup${topupLvl}` : ''} imm__${modalClass}__modal_dialog`}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <div className={`imm__modal_content imm__${modalClass}__modal_content`}>
                <div className="imm__report_bug_inner_wrap">
                    <button className="close_btn" onClick={onCloseModal}>
                        <BsX />
                    </button>

                    <h3 className="text_center color_primary pt-2 pb-4">Report a bug</h3>

                    <div className="imm__input_container__primary" ref={bugLocationRef}>
                        <div className="label__cont">
                            <label>Location of bug <span className="color_error">*</span></label>
                            <div className="mice">Tell us where did you find the bug (E.g. home page)</div>
                        </div>
                        <div className="input_group">
                            <input
                                type="text"
                                placeholder="Enter location of bug"
                                value={bugLocation}
                                onChange={(e) => setBugLocation(e.target.value)}
                            />
                        </div>
                        <div className="input_error__cont">
                            <span className="input_error">
                                Location of bug is required!
                            </span>
                        </div>
                    </div>

                    <div className="imm__input_container__primary pt-4" ref={bugDescrRef}>
                        <div className="label__cont">
                            <label>Description of bug <span className="color_error">*</span></label>
                        </div>
                        <div className="input_group">
                            <textarea
                                rows={6}
                                placeholder="Enter description of bug"
                                value={bugDescription}
                                onChange={(e) => setBugDescription(e.target.value)}
                            />
                        </div>
                        <div className="input_error__cont">
                            <span className="input_error">
                                Description of bug is required!
                            </span>
                        </div>
                    </div>

                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        className="pt-4 pb-5"
                        onChange={(e) => setImage((e.target.files as any)[0] ?? null)}
                    />

                    <button
                        disabled={isProcessRunning}
                        className="btn_block imm__regular__btn imm__btn__primary"
                        onClick={onSubmit}
                    >
                        {
                            isProcessRunning ?
                                <i className='imm__btn_loader' />
                                : 'Submit bug'
                        }
                    </button>

                    <div className="operation_complete_response">
                        {
                            operationStatus === 'success'
                                ? <span className="text_center color_success pt-3">Bug has been reported successfully. Thank you for helping us improve our platform!</span>
                                : operationStatus === 'error'
                                    ? <span className="text_center color_error pt-3">An error occurred while reporting the bug. Please try again later!</span>
                                    : null
                        }
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default ReportBugModal;