import api from ".";

import cookiesHelper from "../helpers/cookies";

export const getPackages = async () => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.get(`packages`);
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

export const createPaymentIntent = async (payload: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.post(`payments/stripe/payment-intent/create`, payload);
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

export const updatePaymentIntent = async (payload: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        const response = await api.post(`payments/stripe/payment-intent/update`, payload);
        return response.data;
    } catch (error: any) {
        throw error;
    }
}

export const createSubscription = async (payload: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.post(`subscriptions/subscribe`, payload);
    } catch (error: any) {
        throw error;
    }
}

export const verifyPromoCode = async (payload: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.get(`promotions/verify/${payload.packageId}/${payload.code}`);
    } catch (error: any) {
        throw error;
    }
}


export const cancelSubscription = async (payload: any) => {
    const token = cookiesHelper.getCookie(process.env.REACT_APP_AUTH_TOKEN_NAME as string);
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    try {
        return await api.post(`subscriptions/cancel`, payload);
    } catch (error: any) {
        throw error;
    }
}